import React from "react";

export default function EmbedYouTubeVideo({
  url,
  title,
  autoplay = false,
  height = "100%",
  wrapperHeight = 0,
}) {
  const fixUrl = new URL(url);
  let id = null;
  if (
    fixUrl.host.includes("youtube.com") &&
    fixUrl.pathname.includes("/embed")
  ) {
    id = fixUrl.pathname.slice(fixUrl.pathname.lastIndexOf("/"));
  } else {
    id = fixUrl.searchParams.get("v");

    if (fixUrl.host === "youtu.be") {
      id = fixUrl.pathname.slice(1);
    }
  }

  const embedUrl = new URL(
    `https://www.youtube.com/embed/${id}${autoplay ? "?autoplay=1" : ""}`
  );

  return (
    <div
      className="gatsby-resp-iframe-wrapper"
      style={{
        paddingBottom: "56.25%",
        position: "relative",
        height: wrapperHeight,
        overflow: "hidden",
        // marginBottom: "1.0725rem",
      }}
    >
      <iframe
        loading="lazy"
        src={embedUrl.href}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height,
        }}
        title={title}
      ></iframe>
    </div>
  );
}
